import gsap from 'gsap';
import { mapGetters } from 'vuex';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { KButton } from '@/components';
import KFooter from '../WebsiteFooter.vue';
import svg from './Svg';
import KPartners from '../KwertyFor/Partners.vue';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Home',
  components: {
    KButton,
    KFooter,
    KPartners,
  },
  data: () => ({
    // eslint-disable-next-line vue/max-len
    searchSvg: {
      path1: svg.searchSvg.path1,
      path2: svg.searchSvg.path2,
      path3: svg.searchSvg.path3,
    },
    compareSvg: {
      path1: svg.compareSvg.path1,
      path2: svg.compareSvg.path2,
    },
  }),
  mounted() {
    const {
      // hero area
      mainTitle,
      description,
      ctaContainer,
      aboutSection,
      aboutContent,

      // section two
      sectionTwo,
      sectionTwoHead,
      sectionTwoDesc,

      // section two items
      rect1,
      rect2,
      rect3,
      rect4,
      findSvg,
      text,
      search,
      trigger,
      compare,
      compText,
      compLeft,
      compLeftCirc,
      compRight,
      compRightCirc,
      rectGroup,
      trigger2,
      img1,
      img2,
      trigger3,
      searchContent,
      compareContent,
      embedContent,
      kwertyFor,
      kwertyForCards,
    } = this.$refs;

    const speedUnit = 0.9;

    /* ========================= *
    * HERO ANIMATION
    * ========================= */
    const heroTl = gsap.timeline();

    const fadeUp = {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: 'ease.out',
    };

    heroTl.from(mainTitle, { ...fadeUp, delay: 0.05 });

    heroTl.from(description, { ...fadeUp }, '-=0.3');

    heroTl.from(ctaContainer, { ...fadeUp, y: 0, scale: 0.975 }, '-=0.05');

    heroTl.from(aboutSection, { ...fadeUp }, '-=0.5');

    heroTl.from(aboutContent, { ...fadeUp, y: 0, scale: 0.95 }, '<+=0.4');

    /* ========================= *
    * SECTION TWO ELEMENTS
    * ========================= */
    const secTwoTl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionTwo,
        start: '0 90%',
        toggleActions: 'play none none none',
      },
      duration: 0.5,
    });

    secTwoTl.from(sectionTwoHead, { ...fadeUp });
    secTwoTl.from(sectionTwoDesc, { ...fadeUp }, '<+=0.2');

    /* ========================= *
    * FIND DATA TIMELINE START
    * ========================= */
    findSvg.style.position = 'relative';
    text.style.position = 'relative';
    search.style.position = 'relative';

    const findTl = gsap.timeline({
      scrollTrigger: {
        trigger,
        start: '0 80%',
        toggleActions: 'restart none none reset',
      },
      ease: 'ease.out',
    });

    const tl = gsap.timeline({
      yoyo: true,
      repeat: 1,
    });

    tl.to(rect4, {
      duration: 0.5 * speedUnit,
      x: -(rect4.getAttribute('x') - 64),
    });

    tl.to(
      rect1,
      {
        duration: 0.5 * speedUnit,
        x: -(rect1.getAttribute('x') - 110),
      },
      '<+=0.1',
    );

    tl.to(
      rect2,
      {
        duration: 0.5 * speedUnit,
        x: -(rect2.getAttribute('x') - 64),
      },
      '<+=0.35 ',
    );

    tl.to(
      rect3,
      {
        duration: 0.5 * speedUnit,
        x: -(rect3.getAttribute('x') - 64),
      },
      '<+=0.25',
    );

    findTl
      .from(findSvg, {
        top: 300,
        duration: 1 * speedUnit,
        ease: 'ease.in',
        yoyo: true,
      })
      .from(
        text,
        {
          duration: 1 * speedUnit,
          opacity: 0,
          y: 40,
          ease: 'back.out(1)',
        },
        '<+=0.5',
      )
      .from(
        search,
        {
          duration: 0.4 * speedUnit,
          opacity: 0,
          y: 40,
        },
        '-=0.3',
      )
      .add(tl, '-=0.15');

    /* ========================= *
    * COMPARE TIMELINE START
    * ========================= */
    const rectGroupTl = gsap.timeline();
    rectGroupTl.add('start');

    Array.from(rectGroup.querySelectorAll('rect')).forEach((rect) => {
      rectGroupTl.from(
        rect,
        {
          duration: 0.75 * speedUnit,
          width: 0,
          delay: Math.random() * 1,
          ease: 'rough',
        },
        'start',
      );
    });

    const compLeftTl = gsap.timeline({ ease: 'power2.out' });
    const compRightTl = gsap.timeline({ ease: 'power2.out' });

    compLeftTl
      .from(compLeft, {
        y: 40,
        opacity: 0,
        duration: 0.35,
      })
      .to(compLeftCirc, {
        x: 80,
        repeat: 1,
        yoyo: true,
      });

    compRightTl
      .from(compRight, {
        y: 40,
        opacity: 0,
        duration: 0.35,
      })
      .to(compRightCirc, {
        x: 80,
        repeat: 1,
        yoyo: true,
        delay: 0.3 * speedUnit,
      });

    const compareTl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger2,
        start: '0 70%',
        toggleActions: 'restart none none reset',
      },
      ease: 'ease.out',
      // remove if you don't want infinite scroll
      // repeat: Infinity,
      // yoyo: true,
      // repeatDelay: 1,
    });

    compareTl
      .from(compare, {
        y: 300,
        opacity: 0,
        duration: 1 * speedUnit,
        ease: 'ease.in(1)',
        yoyo: true,
      })
      .from(compText, {
        y: 40,
        opacity: 0,
        duration: 0.5 * speedUnit,
      })
      .add('controls', '-=0.35');

    compareTl.add(compLeftTl, 'controls');
    compareTl.add(compRightTl, 'controls');
    compareTl.add(rectGroupTl, '-=0.35');

    /* ========================= *
    * DOWNLOAD TIMELINE START
    * ========================= */
    const downloadTl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger3,
        start: '0 80%',
        toggleActions: 'restart none none reset',
      },
      ease: 'ease.out',
      // remove if you don't want infinite scroll
      // repeat: Infinity,
      // yoyo: true,
      // repeatDelay: 1,
    });

    downloadTl.from(img1, {
      duration: 0.65,
      x: 250,
      ease: 'back.out',
      opacity: 0,
    }).from(img2, {
      duration: 0.65,
      x: -250,
      ease: 'back.out',
      opacity: 0,
    }, '<+=0.45');

    const searchContentTl = gsap.timeline({
      scrollTrigger: {
        trigger: searchContent,
        start: '0 90%',
      },
    });

    const searchH2 = searchContent.querySelector('h2');
    const searchP = searchContent.querySelector('p');
    const searchButton = searchContent.querySelector('button');

    searchContentTl.from(searchH2, { ...fadeUp });
    searchContentTl.from(searchP, { ...fadeUp });
    searchContentTl.from(searchButton, { ...fadeUp });

    const compareContentTl = gsap.timeline({
      scrollTrigger: {
        trigger: compareContent,
        start: '0 90%',
      },
    });

    const compareH2 = compareContent.querySelector('h2');
    const compareP = compareContent.querySelector('p');
    const compareButton = compareContent.querySelector('button');

    compareContentTl.from(compareH2, { ...fadeUp });
    compareContentTl.from(compareP, { ...fadeUp });
    compareContentTl.from(compareButton, { ...fadeUp });

    const embedContentTl = gsap.timeline({
      scrollTrigger: {
        trigger: embedContent,
        start: '0 90%',
      },
    });

    const embedH2 = embedContent.querySelector('h2');
    const embedP = embedContent.querySelector('p');
    const embedButton = embedContent.querySelector('button');

    embedContentTl.from(embedH2, { ...fadeUp });
    embedContentTl.from(embedP, { ...fadeUp });
    embedContentTl.from(embedButton, { ...fadeUp });

    const kwertyForTl = gsap.timeline({
      scrollTrigger: {
        trigger: kwertyFor,
        start: '0 90%',
      },
    });

    const heading = kwertyFor.querySelector('.heading');
    const title = kwertyFor.querySelector('.title');

    kwertyForTl.from(heading, { ...fadeUp });
    kwertyForTl.from(title, { ...fadeUp });

    const kwertyForCardsTl = gsap.timeline({
      scrollTrigger: {
        trigger: kwertyForCards,
        start: '0 90%',
      },
    });
    const cards = Array.from(kwertyForCards.querySelectorAll('.card'));
    kwertyForCardsTl.from(cards[0], { ...fadeUp });
    kwertyForCardsTl.from(cards[1], { ...fadeUp }, '-=0.2');
    kwertyForCardsTl.from(cards[2], { ...fadeUp }, '-=0.2');
  },
  computed: {
    ...mapGetters('auth', ['getToken']),
  },
};
