import { mapActions, mapMutations } from 'vuex';
import { KAuth, KButton, KInput } from '@/components';
import Validators from '@/mixins/Validators';
import { resendOtp } from '@/api/auth';

export default {
  name: 'Login',
  components: {
    KAuth,
    KInput,
    KButton,
  },
  mixins: [Validators],
  data: () => ({
    user: {},
    touched: {},
    error: {},
    general_error: '',
    isLoading: false,
    next: '/search',
    int: null,
  }),
  mounted() {
    const {
      $route: {
        query: { next },
      },
    } = this;
    if (next) {
      this.next = next;
    }
  },
  updated() {
    const { form } = this.$refs;
    const {
      email: { value: email },
      password: { value: password },
    } = form;
    if (this.user.email !== email || this.user.password !== password) {
      this.user = { email, password };
    }
  },
  computed: {
    formInvalid() {
      const errors = Object.values(this.error);
      let values = Object.values(this.user);
      const fields = ['email', 'password'];
      const formExists = this.$refs.form;
      if (formExists) {
        values = fields.map((f) => this.$refs.form[f].value);
      }

      return errors.some((k) => !!k) || !(values.length === 2 && values.every((v) => !!v));
    },
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      fetchProfile: 'auth/fetchProfile',
    }),
    ...mapMutations({
      reset: 'auth/RESET',
    }),
    async logUserIn() {
      if (this.formInvalid) return;
      const { email, password } = this.user;
      try {
        this.isLoading = true;
        const response = await this.login({ user: { email, password } });
        if (response.error) throw Error(response.error);
        this.general_error = '';
        // check if user is verified
        // if not send user to otp page
        if (!response.data.isVerified) {
          this.$toast.show({ message: 'Verify your account to continue' });
          await resendOtp({ email });
          this.$router.push({ name: 'Otp', query: { email, next: this.next } });
          // check if user is disabled
        } else if (response.data.status !== 'enabled') {
          this.general_error = 'Sorry, your account has been disabled. Contact us if this is a mistake.';
          this.reset();
        } else {
          this.$toast.show({ message: response.message });
          this.getProfile();
          this.$router.replace(this.next);
        }
      } catch (e) {
        this.general_error = e.message;
      } finally {
        this.isLoading = false;
      }
    },
    async getProfile() {
      try {
        const profile = await this.fetchProfile();
        if (profile.error) {
          throw Error(profile.error);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
