<template>
  <k-auth>
    <template v-slot:otpTitle>
      <p class="title">
        Almost there! We sent a 4-digit code to <span>{{ title }}</span>
      </p>
    </template>
    <template v-slot:description>
      <p>
        Please type in the One-Time Password (OTP) sent to your provided email address. It is valid
        for 5 minutes.
      </p>
    </template>
    <form class="form__items" @submit.prevent="verifyEmail">
      <k-input label="OTP" name="Otp" :error="error" v-model="user.otp" />
      <k-button :loading="isLoading" size="full" submit :disabled="user.otp.length !== 4">
        Verify your email</k-button>
    </form>
    <k-button size="full" variant="link" submit @click="sendOtpAgain">
      Didn't receive a code? Try again</k-button
    >
  </k-auth>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { KButton, KInput, KAuth } from '@/components';
import { resendOtp } from '@/api/auth';

export default {
  name: 'otp',
  components: {
    KButton,
    KInput,
    KAuth,
  },
  data: () => ({
    user: {
      otp: '',
    },
    isLoading: false,
    error: '',
    title: '',
    email: '',
    next: '',
  }),
  mounted() {
    const {
      $route: { query },
    } = this;
    this.next = query.next || '';
    const email = query.email || this.userFromState.email;

    if (!email) this.title = 'your email';
    this.email = email;
    const name = email.split('@')[0];
    const domain = email.split('@')[1].split('.')[0];
    const hashName = name.slice(0, 2) + '*'.repeat(name.length > 2 ? name.length - 2 : 0);
    const hashDomain = domain[0] + '*'.repeat(domain.length > 1 ? domain.length - 1 : 0);
    const hashEmail = email.split('@')[1].split('.')[1];
    this.title = `${hashName}@${hashDomain}.${hashEmail}`;
  },
  computed: {
    ...mapGetters({
      userFromState: 'auth/getUser',
      userId: 'auth/getUserId',
    }),
  },
  methods: {
    ...mapActions({
      verifyOtp: 'auth/verifyOtp',
      resendOtp: 'auth/resendOtp',
    }),
    async verifyEmail() {
      const {
        user: { otp },
        userId,
      } = this;
      this.isLoading = true;
      try {
        const response = await this.verifyOtp({ otp, userId });
        if (!response.error) {
          this.$toast.show({ message: 'Email verification Successful' });
          this.$router.push(this.next || '/search');
        } else {
          throw Error(response.error);
        }
        this.isLoading = false;
      } catch (error) {
        this.$toast.show({ message: error });
        this.isLoading = false;
      }
    },
    async sendOtpAgain() {
      const { email } = this;
      try {
        await resendOtp({ email });
        this.$toast.show({ message: 'Check your mail for OTP' });
      } catch (e) {
        this.$toast.show({ message: e.message });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.9rem;
  color: $black;
  padding-bottom: 1.6rem;
  span{
    word-break: break-all;
  }
}
</style>
