import {
  getIndicators,
  getIndicatorsInCategory,
  searchIndicators,
  searchIndicatorsInCategory,
} from '@/api/indicators';
import errorHandler from '@/utils/error-handler';

export default {
  getInitialIndicators: ({ commit }) =>
    // eslint-disable-next-line
    getIndicators()
      .then(({ data: { data } }) => {
        commit('ADD_INDICATORS', data.indicator);
        commit('ADD_CATEGORIES', data.indicator);

        return data;
      })
      .catch((response) => errorHandler(response)),
  // call different endpoint if category is present
  fetchIndicatorsWith: ({ commit }, body) => {
    const { category, name } = body;
    let service = searchIndicators;
    const nameCategoryOnly = name && category && !body.country;
    const categoryCountryOnly = !name && category && body.country;
    const categoryOrCountryAlone = !name && (category || body.country);
    if (nameCategoryOnly) {
      service = searchIndicatorsInCategory;
    } else if (categoryCountryOnly || categoryOrCountryAlone) {
      service = getIndicatorsInCategory;
    }

    return service(body)
      .then(({ data: { data } }) => {
        commit('ADD_INDICATORS', data.indicator || data);
        commit('ADD_CATEGORIES', data.indicator || data);
        return data;
      })
      .catch((response) => errorHandler(response));
  },
};
