<template>
  <nav id="nav" :class="{ hide: $route.meta.hideNav }">
    <router-link to="/"
      ><img
        alt="kwerty logo"
        class="logo"
        src="https://one-percent-bucket-1.s3.us-west-2.amazonaws.com/kwertyassets/logo.png"
    /></router-link>

    <ul class="visible-links">
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>
        <router-link to="/countries">Countries</router-link>
      </li>
      <li>
        <router-link to="/blog">Blog</router-link>
      </li>
      <li>
        <router-link to="/about-us">About Us</router-link>
      </li>
      <li>
        <router-link to="/contact-us">Contact Us</router-link>
      </li>
    </ul>

    <div class="controls">
      <div class="toggle-button" @click="isOpen = true">
        Menu
        <svg
          fill="none"
          height="16"
          viewBox="0 0 18 16"
          width="18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z" fill="black" />
        </svg>
      </div>
      <div v-if="loggedIn" class="profile-pic">
        <router-link to="/profile">
          <img alt="" :src="userData.imageUrl || dummyImage" />
        </router-link>
      </div>
    </div>

    <transition name="appear">
      <div v-if="isOpen" class="overlay" @click="close(true)"></div>
    </transition>

    <div ref="navContent" :class="['nav-content', { isOpen: isOpen }]">
      <div class="close" @click="close(true)">
        <img src="@/assets/close.png" alt="" />
      </div>
      <div :class="['profile-info', { notLoggedIn: !loggedIn }]">
        <template v-if="loggedIn">
          <div class="profile-details">
            <img alt="" :src="userData.imageUrl || dummyImage" />
            <h3>{{ userData.fullName }}</h3>
            <p @click="close">
              <router-link to="/profile">Profile Settings</router-link>
            </p>
          </div>
        </template>
        <template v-else>
          <h3>You're not logged in</h3>
          <p>Please sign in to have full access.</p>
        </template>
      </div>
      <div class="nav-list">
        <div v-for="group in menu" :key="group.title" class="menu-group">
          <h3 class="group-title">{{ group.title }}</h3>
          <ul>
            <li v-for="child in group.children" :key="child.title" @click="close()">
              <router-link :to="child.url">
                {{ child.title }}
                <span v-if="child.comingSoon" class="coming-soon">Coming Soon</span>
              </router-link>
            </li>
          </ul>
        </div>

        <!-- sign out     -->
        <div v-if="loggedIn" class="sign-out menu-group">
          <div class="group-title">sign out</div>
          <ul>
            <li class="sign-out" @click="logout">Sign out</li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Navbar from './Navbar';

export default Navbar;
</script>

<style lang="scss" scoped src="./Navbar.scss"></style>
