export default [
  {
    title: 'apps',
    children: [
      { title: 'Data Search', url: '/search' },
      { title: 'Country Dashboard', url: '/country' },
      { title: 'Fact Checking', url: '#', comingSoon: true },
    ],
  },
  {
    title: 'company',
    children: [
      { title: 'Blog', url: '/blog' },
      { title: 'About Us', url: '/about-us' },
      { title: 'Careers', url: '/careers' },
    ],
  },
  {
    title: 'help/support',
    children: [
      { title: 'Contact Us', url: '/contact-us' },
      // { title: 'Customer Support', url: '/customer-support' },
      { title: 'Legal', url: '/legal' },
    ],
  },
];
