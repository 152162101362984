<template>
  <div ref="scroll" class="country-indicator-body">
    <section class="header-section">
      <h2 class="page-header"><span>.</span>{{ country.replace(/-/g, ' ') }} indicators</h2>

      <div class="tab">
        <ul class="tab-items">
          <!-- the currentTab property is for passing around the active class -->
          <!-- if the currentTab property is equal to it's index, the active class will be added -->
          <!-- the overview tab is seperated from the rest so it can showTopIndicators on click -->
          <li
            @click="
              showTopIndicators = true;
              currentTab = overviewIndex;
              isTabItem = false;
            "
            :class="{ active: currentTab === overviewIndex }"
            ref="overview"
          >
            Overview
          </li>
          <!-- removeActiveClass() removes the active class from the overview tab -->
          <li
            v-for="(item, index) in tabItems"
            :key="item"
            @click="
              showTopIndicators = false;
              tabIndex = index;
              currentTab = index;
              isTabItem = true;
              removeActiveClass();
              setCategory();
            "
            :class="{ active: currentTab === index }"
          >
            {{ item }}
          </li>
          <!--  -->
          <!--  -->
          <!--  -->
          <!--  -->
        </ul>
        <div class="mobile-tab-items">
          <div class="mobile-tab-button-wrapper" @click="showModal = true">
            <div class="mobile-tab-button">
              <h6>Indicator Category</h6>
              <h4 v-if="isTabItem">{{ tabItems[tabIndex] }}</h4>
              <h4 v-else>Overview</h4>
            </div>
            <div class="mobile-tab-button-arrow">
              <img src="../../../../src/assets/arrow-down.svg" alt="" />
            </div>
          </div>
          <div class="backdrop" v-if="showModal" @click="showModal = false"></div>
          <div class="tab-items-mobile" v-if="showModal" @click="showModal = false">
            <div class="items">
              <div>
                <p
                  @click="
                    showTopIndicators = true;
                    isTabItem = false;
                  "
                >
                  Overview
                </p>
              </div>
              <div
                v-for="(item, index) in tabItems"
                :key="item"
                @click="
                  showTopIndicators = false;
                  tabIndex = index;
                  currentTab = index;
                  isTabItem = true;
                  removeActiveClass();
                  setCategory();
                "
              >
                <p>{{ item }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-page-header" v-if="showTopIndicators">
        <h3 class="tab-header">Top Indicators</h3>
        <span class="country-name">{{ country.split('-').join(' ') }}</span>
      </div>
      <div class="tab-page-header" v-else>
        <h3 class="tab-header">{{ tabItems[tabIndex] }} Indicators</h3>
        <span class="country-name">{{ country.split('-').join(' ') }}</span>
      </div>
    </section>
    <section class="body">
      <!-- loading animation for top indicators -->
      <div class="loading-indicators" v-if="pageLoading">
        <div class="indicator" v-for="item in rangeArray" :key="item">
          <div class="skeleton"></div>
        </div>
      </div>
      <!--  -->
      <div class="top-indicators" v-if="showTopIndicators">
        <div v-for="indicator in topIndicators" :key="indicator.id">
          <router-link
            :to="`/advanced/${indicator.name.replace(/%/g, '%25').replace(/\//g, '%2F')}/${country
              .split('-')
              .join('%20')}`"
          >
            <div class="indicator">
              <p>{{ indicator.name }}</p>
            </div>
          </router-link>
        </div>
      </div>
      <section class="indicators" v-else>
        <div v-if="categoryIndicators.length">
          <div
            v-for="(obj, index) in categoryIndicators"
            :key="index"
            class="indicator-section"
            :style="`height: ${obj.listHeight}px;`"
          >
            <span
              @click="
                obj.isOpen = !obj.isOpen;
                getListHeight(index, obj);
                closeOthers(index);
              "
            >
              <p>{{ obj.title }}</p>

              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                :class="[obj.isOpen ? 'rotate' : '']"
              >
                <path
                  d="M6.99999 5.17217L11.95 0.222168L13.364 1.63617L6.99999 8.00017L0.635986 1.63617L2.04999 0.222168L6.99999 5.17217Z"
                  fill="#999999"
                />
              </svg>
            </span>
            <div class="indicator-list" ref="indicatorList">
              <router-link
                :to="`/advanced/${indicator.replace(/%/g, '%25').replace(/\//g, '%2F')}/${country
                  .split('-')
                  .join('%20')}`"
                v-for="(indicator, index) in obj.indicators"
                :key="index"
              >
                <p>
                  {{ indicator }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="no-indicator" v-else>
          <img src="../../../assets/light-bulb.svg" alt="" />
          <p style="margin-bottom: 1em">No Indicator Available for this Category</p>
        </div>
      </section>

      <div class="country-dashboard" v-if="showCountryDetails">
        <h4>Country Dashboard</h4>
        <h3>{{ country.split('-').join(' ') }}</h3>
      </div>
      <div class="country-details" v-if="showCountryDetails">
        <div class="country-image">
          <div v-if="pageLoading" class="skeleton"></div>
          <img v-else :src="countryDetails.imageUrl" alt="" />
        </div>
        <div class="country-text">
          <p>
            {{ countryDetails.description }}
          </p>

          <router-link :to="`/country/${country.split('-').join(' ')}`">
            <button>
              See an overview of <span>{{ country.split('-').join(' ') }}</span>
            </button>
          </router-link>
        </div>
      </div>
    </section>
    <section class="footer">
      <k-footer></k-footer>
    </section>
  </div>
</template>

<script>
import KFooter from '../WebsiteFooter.vue';
import { mapActions } from 'vuex';
import { fetchCountrySummary } from '@/api';

export default {
  head: {
    title() {
      return { inner: `${this.capitalize(this.country.split('-').join(' '))} Indicators` };
    },
  },
  components: {
    KFooter,
  },
  data() {
    return {
      // this gets the country from the URL
      country: this.$route.params.country,
      tabItems: [
        'Agriculture',
        'Economy',
        'Education',
        'Health',
        'Infrastructure',
        'Labour',
        'Monetary',
        'Price',
        'Population',
        'Tax',
        'Tourism',
        'Trade',
      ],
      topIndicators: [],
      categoryIndicators: [],
      countryDetails: {},
      showModal: false,
      showTopIndicators: true,
      tabIndex: -1,
      isTabItem: false,
      overviewIndex: '',
      currentTab: '',
      dataVariable: [],
      isPageLoading: true,
      rangeArray: [],
    };
  },
  methods: {
    ...mapActions({
      fetchIndicators: 'indicators/fetchIndicatorsWith',
    }),
    removeActiveClass() {
      this.$refs.overview.className = '';
    },
    setCategory() {
      // gets the categories and sorts them alphabetically
      this.categoryIndicators = this.dataVariable.filter(
        (item) => item.category === this.tabItems[this.tabIndex].toLowerCase()
      );
      this.categoryIndicators.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      // console.log(this.categoryIndicators);
      // //

      // renders them in groups of their first letter
      const formattedList = [];
      // creates objects that have the letter stored in 'title' and the indicators that
      // start with the letter stored in a list 'indicators'

      for (const item of this.categoryIndicators) {
        const firstLetter = item.name.charAt(0).toUpperCase();

        // find existing object with same title or create new object
        let obj = formattedList.find((obj) => obj.title === firstLetter);
        if (!obj) {
          obj = {
            title: firstLetter,
            indicators: [],
            isOpen: false,
            // listHeight is to set the height of the div for the purpose of setting the height
            // for the smooth transitioning of the accordion
            listHeight: 60,
          };
          formattedList.push(obj);
        }

        // push the indicators into the list in the objects
        obj.indicators.push(item.name);
      }
      this.categoryIndicators = formattedList;
      // console.log(this.categoryIndicators);
    },
    range(start, end) {
      for (let i = start; i <= end; i++) {
        this.rangeArray.push(i);
      }
      return this.rangeArray;
    },
    capitalize(string) {
      const words = string.split(' ');

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      const capitalize = words.join(' ');
      return capitalize;
    },
    async getCountryData() {
      try {
        let country = this.country;
        const summaryRes = await fetchCountrySummary({ country });
        if (summaryRes.data.data.length) {
          const [countryDetails] = summaryRes.data.data;
          this.countryDetails = countryDetails;
          // console.log(this.countryDetails);
        } else {
          this.countryDetails = {};
        }
      } catch (e) {
        // console.log(e.response?.data.message || 'Something unexpected occurred on the server');
        this.$toast.show({
          message: 'Something unexpected occurred on the server',
        });
      }
    },
    // Elements with the same $ref are returned as an array when called
    getListHeight(index, obj) {
      if (obj.isOpen) {
        let prevListHeight = obj.listHeight;
        obj.listHeight = this.$refs.indicatorList[index].clientHeight + prevListHeight;
        // console.log(obj.listHeight);
      } else {
        obj.listHeight = 60;
      }
    },
    closeOthers(index) {
      for (let item in this.categoryIndicators) {
        if (index != item) {
          this.categoryIndicators[item].listHeight = 60;
          this.categoryIndicators[item].isOpen = false;
          //
          // console.log(this.categoryIndicators[item].isOpen);
        }
      }
    },
  },
  computed: {
    pageLoading() {
      return this.isPageLoading && this.showTopIndicators;
    },
    showCountryDetails() {
      return this.countryDetails.description && this.showTopIndicators;
    },
  },

  async created() {
    this.range(1, 21);
    try {
      const response = await this.fetchIndicators({
        country: this.country.split('-').join(' '),
      });
      // console.log('This is from countries Indicator', response);

      const promise1 = Promise.resolve(response);
      promise1.then((data) => {
        // console.log('Resolved promise data', data);
        this.dataVariable = data;
        if (this.dataVariable.length) {
          this.topIndicators = data.slice(0, 21);
          this.isPageLoading = false;
        }
      });
    } catch (e) {
      // console.log(e.message);
    }
  },

  mounted() {
    // this add the active class to the overview tab on load
    this.$refs.overview.className = 'active';
    this.getCountryData();
  },
};
</script>

<style lang="scss" scoped>
.header-section {
  margin-top: 5em;
  padding-inline: 4em;
  position: fixed;
  top: -6px;
  z-index: 8990;
  backdrop-filter: blur(0.4rem);
  background-color: rgba(255, 255, 255, 0.8);
}

.page-header {
  text-transform: capitalize;
  font-size: 2rem;
  margin-left: 8%;
  margin-bottom: 1.5em;

  & span {
    font-size: 5rem;
    font-family: emoji;
    color: #900f7b;
    margin-right: 0.1em;
  }
}

.tab {
  max-width: fit-content;
  margin-inline: auto;
  margin-bottom: 0em;

  & .tab-items {
    list-style: none;
    padding-left: 0;
    padding-block: 0.4em;
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fafafa;
    border-radius: 9px;

    & li {
      color: #666666;
      margin-bottom: 0;
      padding: 0.4em 1.15em;
      margin-inline: 0.5em;
      cursor: pointer;

      &:hover {
        color: #900f7b;
        background-color: white;
        border-radius: 7px;
        border: 1px solid #f2f2f2;
      }
    }

    & .active {
      color: #900f7b;
      background-color: #ffff;
      border-radius: 7px;
      border: 1px solid #f2f2f2;
    }
  }

  & .mobile-tab-items {
    display: none;
  }
}

@media (max-width: 788px) {
  .header-section {
    width: 100vw;
    padding-inline: 1.4em;
  }

  .tab {
    width: 100%;
    max-width: none;

    & .tab-items {
      display: none;
    }

    & .mobile-tab-items {
      display: flex;
      justify-content: space-between;
      border: solid 1px #d9d9d9;
      border-radius: 8px;
      background-color: #fff;

      & .mobile-tab-button-wrapper {
        width: 100%;
        padding: 1em 2em;
        display: flex;
        justify-content: space-between;
      }

      & .mobile-tab-button {
        & h4 {
          font-weight: 500;
          font-size: 2rem;
        }

        & h6 {
          font-size: 1.2rem;
          font-weight: 400;
        }
      }

      & .mobile-tab-button-arrow {
        position: relative;
        margin-bottom: 0.5em;
        margin-right: 1em;

        & img {
          position: absolute;
          bottom: 0;
        }
      }

      .backdrop {
        position: fixed;
        top: -64px;
        left: 0;
        background-color: rgba(128, 128, 128, 0.5);
        backdrop-filter: blur(2px);
        height: 100vh;
        width: 100vw;
        z-index: 9002;
      }

      .tab-items-mobile {
        height: 31em;
        width: 85%;
        background-color: #fff;
        padding: 1em 1em 0 1em;
        margin-inline: auto;
        position: fixed;
        top: 110px;
        left: 20px;
        right: 20px;
        z-index: 9005;
        border-radius: 8px;
        overflow-y: scroll;

        .items p {
          font-size: 1.4rem;
          padding: 10px;
        }

        .items > .active {
          background-color: #d9d9d9;
          border-radius: 8px;
        }
      }
    }
  }
}

.body {
  max-width: 1260px;
  margin-inline: auto;
  padding-inline: 5%;
  position: relative;
  top: 240px;
}

.tab-page-header {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 2em;
  margin-left: 8%;
  padding-bottom: 1em;

  & .tab-header {
    font-size: 1.5rem;
    margin-block: 0;
    margin-right: 0.6em;
    font-weight: 600;
  }

  & .country-name {
    text-transform: capitalize;
    font-size: 1.2rem;
    color: #ffff;
    background-color: #900f7b;
    padding-inline: 2em;
    margin-block: 0;
    border-radius: 8px;
    font-weight: 600;
  }
}

.top-indicators {
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  grid-gap: 7px;
  margin-block: 3em;

  & .indicator {
    margin-bottom: 0 !important;
    padding: 1.2em 1.7em;
    border: #f2f2f2 1px solid;
    border-radius: 8px;
    height: 100%;
    cursor: pointer;

    &:hover {
      background-color: #900f7b;
      color: #ffff;
    }

    // &:has(.skeleton) {
    //   animation: pulse-bg 1s infinite;
    // }

    // &:has(.skeleton):hover {
    //   background-color: #ffff;
    // }
  }
}

.loading-indicators {
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  grid-gap: 7px;
  margin-block: 3em;

  & .indicator {
    margin-bottom: 0 !important;
    padding: 1.2em 1.7em;
    border: #f2f2f2 1px solid;
    border-radius: 8px;
    cursor: pointer;
  }

  .skeleton {
    width: 100%;
    padding: 1.2em;
    background-color: #ededed;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3.8rem;
      top: 0;
      left: -32px;
      background: rgba(255, 255, 255, 0.9);
      filter: blur(16px);
      animation: suspense 1.5s 0.05s ease infinite;
    }
  }
}

@keyframes pulse-bg {
  from {
    left: -2px;
  }
  to {
    left: calc(100% + 2px);
  }
}

section.indicators {
  margin-block: 2em;

  .indicator-section {
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    margin-bottom: 1em;
    overflow: hidden;
    // max-height: 60px;
    transition: 0.4s linear;

    // &.open-indicators {
    //   max-height: 1300px !important;
    // }

    & > span {
      display: flex;
      align-items: center;
      height: 60px;
      cursor: pointer;

      & > .rotate {
        transform: rotate(-180deg);

        path {
          fill: #900f7b;
        }
      }

      & > p:first-child {
        padding: 0.5em 0 0.5em 1em;
        width: 95%;
      }

      & > svg {
        width: 14px;
        transition: 0.2s linear;
      }
    }

    .indicator-list {
      transform-origin: top;

      & p {
        padding: 1em;
        cursor: pointer;

        &:hover {
          background-color: #ededed;
          color: #900f7b;
        }
      }
    }
  }

  .no-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-block: 3em;
    }

    p {
      color: #666666;
      font-weight: 600;
      font-family: 'Public Sans';
    }
  }
}

@media (max-width: 812px) {
  .loading-indicators,
  .top-indicators {
    grid-template: auto / repeat(2, 1fr);
  }
}
@media (max-width: 560px) {
  .loading-indicators,
  .top-indicators {
    grid-template: auto / repeat(1, 1fr);
  }
}

.country-dashboard {
  text-transform: capitalize;

  & h3 {
    font-size: 2rem;
  }

  & h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: 0.1em;
  }
}

.country-details {
  width: 72.2%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  min-width: fit-content;

  & > div {
    margin-bottom: 0;
  }

  .country-image {
    position: relative;
    height: auto;
    width: 365px;

    .skeleton {
      width: 100%;
      height: 100%;
      background-color: #ededed;

      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 3.8rem;
        top: 0;
        left: -32px;
        background: rgba(255, 255, 255, 0.9);
        filter: blur(16px);
        animation: suspense 1.5s 0.05s ease infinite;
      }
    }

    img {
      width: 100%;
    }
  }

  .country-text {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    & p {
      margin-bottom: 1em;
    }

    & button {
      cursor: pointer;
      width: fit-content;
      border-radius: 8px;
      border: #d9d9d9 solid 1px;
      background-color: #ffff;
      padding: 0.7em 2.15em;
      font-weight: 600;
      transition: 0.2s linear;

      &:hover {
        background-color: #900f7b;
        border: #900f7b solid 1px;
        color: #fff;
      }

      & span {
        text-transform: capitalize;
      }
    }
  }
}

@media (max-width: 822px) {
  .country-details {
    flex-wrap: wrap;
    gap: 10px;

    & button {
      margin-top: 1.5em;
    }
  }
}

@media (max-width: 822px) {
  .country-details {
    min-width: auto;
    width: auto;

    .country-image {
      & img {
        width: 100%;
      }
    }
  }
}

.similar-countries {
  & > p {
    text-transform: uppercase;
    font-size: 1.1rem;
  }
}

.countries-wrapper {
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 0 !important;

  .countries {
    display: flex;
    justify-content: space-between;
    width: 138%;
    min-width: 1434px;
    transition-duration: 500ms;

    & p {
      border: solid 1px #d9d9d9;
      padding: 0.71em 2.2em;
      border-radius: 32px;
      font-weight: 600;
    }
  }
}

.footer {
  position: relative;
  top: 240px;
}

.container {
  max-width: 1190px;
}

::-webkit-scrollbar {
  display: none;
}
</style>
