<template>
  <div id="app">
    <k-navbar></k-navbar>
    <router-view/>
    <k-toast />
    <k-check-auth></k-check-auth>
  </div>
</template>

<script>
import { KNavbar, KToast, KCheckAuth } from '@/components';

export default {
  name: 'App',
  components: {
    KNavbar,
    KToast,
    KCheckAuth,
  },
};
</script>

<style lang="scss" src="@/styles/main.scss"></style>
